import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6c65474488166be9d941a7c5a4006ea4/5d222/myth-about-code.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.49999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABzCiVD//EABgQAAIDAAAAAAAAAAAAAAAAAAABESAh/9oACAEBAAEFAqLSD//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAdEAACAgEFAAAAAAAAAAAAAAAAARFBIVFxgZGh/9oACAEBAAE/IeDZGNH2WLKJJK/D/9oADAMBAAIAAwAAABCL3//EABYRAAMAAAAAAAAAAAAAAAAAAAEQYf/aAAgBAwEBPxARP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EFf/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMUGB/9oACAEBAAE/EAL10eXKxUH7ABrQDRvsVMK9CcFWP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "myth-about-programming",
            "title": "myth-about-programming",
            "src": "/static/6c65474488166be9d941a7c5a4006ea4/4b190/myth-about-code.jpg",
            "srcSet": ["/static/6c65474488166be9d941a7c5a4006ea4/e07e9/myth-about-code.jpg 200w", "/static/6c65474488166be9d941a7c5a4006ea4/066f9/myth-about-code.jpg 400w", "/static/6c65474488166be9d941a7c5a4006ea4/4b190/myth-about-code.jpg 800w", "/static/6c65474488166be9d941a7c5a4006ea4/e5166/myth-about-code.jpg 1200w", "/static/6c65474488166be9d941a7c5a4006ea4/b17f8/myth-about-code.jpg 1600w", "/static/6c65474488166be9d941a7c5a4006ea4/5d222/myth-about-code.jpg 5688w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
So at this point I think that you can call me a programmer. The main languages that I can code in are Python, HTML and CSS. I mean HTML and CSS aren't really programming languages but I guess you can throw those in too. Some of my creations are..`}</p>
    <p>{`A to-do List web-app using Django. It makes to do lists. Barely supports user authentication.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.49999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAAwElEQVQoz82OzQqCQBSFfQFxxIUL3/8F3AUK0a4eoQSFoI1WBBYO83OamcZQypFq04HD3Lnc+93jxXEMEgTI8xxGUqJuGpRVhba92pact53zkiQBIQSZBerm8XzCrihAKZ0FCiHM2895URTB9/1nQs45RCdxqy+gXWcu66WH5cDqrwCMsTEwDMMRUGu5XyPdLhQJYOoAtylcdgLTMsPqsDG1BvYLLvUzL0A+Afg6oQa+gwxTuDSZ8BPILPAX/T/wDq3FIUC4NgnCAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "To Do WebApp using python",
          "title": "To Do WebApp using python",
          "src": "/static/f2be7f84c1afb77d3be823df1ad9a5ec/5a190/to-do-webapp.png",
          "srcSet": ["/static/f2be7f84c1afb77d3be823df1ad9a5ec/772e8/to-do-webapp.png 200w", "/static/f2be7f84c1afb77d3be823df1ad9a5ec/e17e5/to-do-webapp.png 400w", "/static/f2be7f84c1afb77d3be823df1ad9a5ec/5a190/to-do-webapp.png 800w", "/static/f2be7f84c1afb77d3be823df1ad9a5ec/c1b63/to-do-webapp.png 1200w", "/static/f2be7f84c1afb77d3be823df1ad9a5ec/29007/to-do-webapp.png 1600w", "/static/f2be7f84c1afb77d3be823df1ad9a5ec/3f919/to-do-webapp.png 1771w"],
          "sizes": "(max-width: 800px) 100vw, 800px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
A physics n-body simulation turned into a game. The true credit belongs to this reddit post: `}<a parentName="p" {...{
        "href": "https://www.reddit.com/r/Python/comments/hr24b1/im_working_on_a_2d_nbody_simulation_using_python/?utm_medium=android_app&utm_source=share"
      }}>{`I'm working on a 2d N-body simulation using Python and Pygame`}</a>{`. I just made some improvements and made it into a game`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/398a8ba270d92fb49313a79e1f2e2185/6e7b6/n-sim.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAAAnUlEQVQ4y82SQQ7EIAhFvf/JegJj0oU4Im1PwZQmTKqROu1sZkGA5PvCF1wIgb33HGNkIuJSyhFSa685pcQ5565Ow4kIABgRTeGor4A94Rmq9TkuJ2yFFaQBjqb7TNj7M1pXpgurFti1VgWE88wwTUzbdkABXkOrNlDyviDct66PZWHfWrctL0tl+RHQ3izdO5ueoHcujyz/Gv8PfANUfr3xKio6rQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "N-body simulation turned into a game",
            "title": "N-body simulation turned into a game",
            "src": "/static/398a8ba270d92fb49313a79e1f2e2185/5a190/n-sim.png",
            "srcSet": ["/static/398a8ba270d92fb49313a79e1f2e2185/772e8/n-sim.png 200w", "/static/398a8ba270d92fb49313a79e1f2e2185/e17e5/n-sim.png 400w", "/static/398a8ba270d92fb49313a79e1f2e2185/5a190/n-sim.png 800w", "/static/398a8ba270d92fb49313a79e1f2e2185/c1b63/n-sim.png 1200w", "/static/398a8ba270d92fb49313a79e1f2e2185/6e7b6/n-sim.png 1491w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`A Pong game using PyGame`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4b0026eb23657a9246ff029101075a29/cd536/pong-game.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABJUlEQVQ4y8WTu07DUAyGnapq2i4wloWbkHg3FlZ4BCoxIVSFkFs7ARITyshQEDSIEdIoabgI3uPHDjolCSA1dGD4ZR9L57N97EOUuKCJA23cR+P+BProDHpwCooGaEQmNm72sDbqYv22i+VgH7XYgh7aWL3qYfPyECvXPb7vYikwYGztgBSQYhda1EeNwQLPzomNVmigGR6hzVYfm6CEk8cOmo8W2g9WZunZy4AH27sMFJiCpjnJmTNT6hUlsSxeVD2y0bkzchWK/SZndkkSrpSmGf8qBZOnEH9uYFn/C1Ttlf1fgeUBTTeg+FY/+pWBOWh94s1YoQLIHj7ZX9AcUGPbSgcVWn7hHzA8x4Lvg16P52yZvxu9mVi88NHxhqB38zNWYSgfegSEb10Q8WgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "A Pong game using Pygame",
            "title": "A Pong game using Pygame",
            "src": "/static/4b0026eb23657a9246ff029101075a29/5a190/pong-game.png",
            "srcSet": ["/static/4b0026eb23657a9246ff029101075a29/772e8/pong-game.png 200w", "/static/4b0026eb23657a9246ff029101075a29/e17e5/pong-game.png 400w", "/static/4b0026eb23657a9246ff029101075a29/5a190/pong-game.png 800w", "/static/4b0026eb23657a9246ff029101075a29/cd536/pong-game.png 1114w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`A CLI calculator that can solve my Maths homework Not much to show there. It is just numbers`}</p>
    <p>{`And my most recent and perhaps the most exciting venture...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/180d82699612757cda72949d75b4fb37/d61c2/site-ss.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAAB6UlEQVQ4y31Tzc6bMBDM+79AH6D3nvoMlXpupVRVk+8LIeHHxgZsMDaG6dpAA5Xaw2BYL+Pd2fHpyjl+cYmLULhKjVtrkGiLe+dwp/U5TMjs/ILDAfkaz0cgVQanR2+R0s9pP+JBeBpPJB4ZEYW1oMSSwDzieyDYIxKvhPemwykPp4REt4Bbj84Y6N7AWIdb+sDl/YaflyuyokQ/2LjXEUrKPRC2PU5bK+G0pwWkmzB5D0+Y5xlaayRJgjwvMAwDpmmKeyGHU274J98TbpVFQrcQtk0DKWuChBACZVmCk9Z1XcfvildQbYvKzf8hdKFSQNDamQEttVTVDZio4xrA6RDRtFC0F1rnkXA+Eu4nGDfcIv6G0h+xxbeu8r81fFWIoz2CBDTpaCNl8b7a6ZiDfw9lq7AIFbrFKk8z4luS4XtaxPWcsRg/2Gbnw2ibbOcn1juUYtEp6BVsMwMw9GCtRiVJS9rrSL+RYiNNvFp1LA4trxOuiWBUDI2sUDIOxlicuJ0ApRW05DTtKk5cCImh7yFDJ6uWOV2MPy3HE5THx68t3hiJ4x35zsLRIaA6P/8Y8eGLjTFLfhxHH+NiXCoMHRZ0s07bMCJhN+PTGbjWxEfpPVVGc4H2My4KOFNc+AUV5Yd2C/e644HwN7j757bsXLIhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "site-image",
            "title": "site-image",
            "src": "/static/180d82699612757cda72949d75b4fb37/5a190/site-ss.png",
            "srcSet": ["/static/180d82699612757cda72949d75b4fb37/772e8/site-ss.png 200w", "/static/180d82699612757cda72949d75b4fb37/e17e5/site-ss.png 400w", "/static/180d82699612757cda72949d75b4fb37/5a190/site-ss.png 800w", "/static/180d82699612757cda72949d75b4fb37/c1b63/site-ss.png 1200w", "/static/180d82699612757cda72949d75b4fb37/29007/site-ss.png 1600w", "/static/180d82699612757cda72949d75b4fb37/d61c2/site-ss.png 1800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`By the way, the repository on GitHub is public so you can check out all the code if you want to.`}</p>
    <p>{`Considering these ventures of mine into the programming world, one can safely say that I am at least a beginner level coder.`}</p>
    <p>{`Now what does it look like when I am coding? Take the website that you are viewing this article on for example. What do you think it looks like when I am coding? The truth is that I am always Googling. Now, I have the hold of the stuff that I already in the website. I am mostly fine with Bootstrap and other things but when it comes to adding things that I don't know about, I am as blank as an average person is. The what is it that differentiates a programmer from an average Joe? It is simply the ability to Google. You need to know how to ask your questions. When I was adding the animated Hamburger menu icon, I had no idea whatsoever on how to do it. I just saw a tutorial on YouTube, some code on Codepen and W3 Schools. Still I couldn't code it. I just made a dummy website with a Navigation bar at the top and a CSS page. I pasted the code from the tutorial into it and then started tweaking to get a gist of how it worked. And now I can animate icons!`}</p>
    <p>{`The way you can define a programmer best in my opinion is "A person who encounters a problem in programming, knows how to phrase a question about the problem and knows how to interpret and use the answer of that question in order to solve that problem and make the program work". That is what makes a good programmer and to be honest minus the code and programming part, everyone of us can be great at solving problems in our own life if only we just know to ask the write questions and the interpret the answers rightly. This is a skill and like any skill it can be developed overtime, and having a head start in that development process can save you a lot of time. In a future article, I shall explore the different ways to improve your 'ability to Google'. So stick around on that and I will see you in the next one.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      